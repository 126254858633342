'use client';

import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as wss from './wordListSlice';
import { RootState, useAppDispatch } from '../store';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosApi from '../axiosApi';
import { AutoComplete } from 'primereact/autocomplete';


export default function WordsPage() {

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const searchWord = useSelector((state: RootState) => state.words.searchWord);
	const words = useSelector(wss.selectWords);
	const totalRecords = useSelector(wss.selectTotalRecords);
	const first = useSelector(wss.selectFirst);
	const rows = useSelector(wss.selectRows);
	const [autocompleteValue, setAutocompleteValue] = useState<string>('');
	const [autocompleteItems, setAutocompleteItems] = useState<string[]>([]);

	//const location = useLocation();

	useEffect(() => {
		dispatch(wss.fetchWords({ first, rows, searchWord: '' }));
	}, [dispatch, first, rows]);

	const handleNext = () => {
		if (isLastPage()) return;
		dispatch(wss.slice.actions.setFirst(first + rows));
	};

	const handlePrev = () => {
		if (first > 0) {
			dispatch(wss.slice.actions.setFirst(first - rows));
		}
	};

	const handleStart = () => {
		dispatch(wss.slice.actions.setFirst(0));
	};

	const handleEnd = () => {
		dispatch(wss.slice.actions.setFirst(Math.floor(totalRecords / rows) * rows));
	};

	const isLastPage = () => {
		return (first + rows) > totalRecords;
	};

	const isFirstPage = () => {
		return first === 0;
	};

	const onPageChange = (event: any) => {
		dispatch(wss.fetchWords({ first, rows, searchWord }));
	};

	const onEdit = async (id: string) => {
		await navigate(`/words/${id}`);
	};
	const attempts = async () => {
		await navigate(`/attempts`);
		// Handle edit action
		//console.log(`Edit word with id: ${id}`);
	};
	const goPractice = async () => {
		const guid = await axiosApi.get<string>(`/api/words1/GetNotFinishedAttemptId`);
		//navigate('/', { replace: true }); // Reset current route

		if (guid?.data) {
			navigate(`/attempts/${guid.data}`);
		} else {
			navigate(`/attempts/0`);
		}
	};
	const autocompleteSearch = async (event: { query: string }) => {
		try {
			const response = await axiosApi.get(`/odata/Words`, {
				params: {
					$count: true,
					$orderby: 'Value',
					$skip: first,
					$top: rows,
					searchWord: event.query,
				},
			});
			//const response = await axiosApi.get<string[]>(`/api/words1/search?query=${event.query}`);
			if (response.data) {
				setAutocompleteItems(response.data);
			}
		} catch (error) {
			console.error('Error searching words:', error);
			setAutocompleteItems([]);
		}
	};

	return (
		<div className='grid'>
			<div className="grid col-12 w-full flex align-items-center justify-content-between">
				<div className="col-12 md:col-6 flex justify-content-start">
					<div className="flex">
						<span style={{ fontSize: '32px' }}>Words <span className="text-color-2"> {totalRecords}</span></span>
					</div>
				</div>
				<div className="col-12 md:col-6 flex justify-content-end">
					<div className="flex-container-1">
						<div className="flex flex-container-2">
							<input className="w-full" name="search" type="search" onChange={(e) => {
								dispatch(wss.slice.actions.setSearchWord(e.target.value));
							}} placeholder="Search" />
						</div>
						<Button className="btn-type-5" icon="pi pi-search" onClick={(e) => {
							dispatch(wss.fetchWords({ first, rows, searchWord }));
						}} label=""></Button>
						<Button className="btn-type-5" icon="pi pi-plus" onClick={(e) => {
							onEdit('0')
						}} label=""></Button>
					</div>
				</div>
				{/* <div className="col-12 md:col-6 flex justify-content-end">
					<div className="flex-container-1">
						<div className="flex flex-container-2">
							<AutoComplete value={autocompleteValue} suggestions={autocompleteItems} completeMethod={autocompleteSearch} onChange={(e) => setAutocompleteValue(e.value)} />
						</div>
						<Button className="btn-type-5" icon="pi pi-search" onClick={(e) => {
							dispatch(wss.fetchWords({ first, rows, searchWord }));
						}} label=""></Button>
						<Button className="btn-type-5" icon="pi pi-plus" onClick={(e) => {
							onEdit('0')
						}} label=""></Button>
					</div>
				</div> */}
			</div>

			<div className="col-12 ">
				<div className="table-wrap">
					<DataTable value={words} dataKey="id" paginator={false} rows={10} lazy={true}
						first={first} onPage={onPageChange}
						totalRecords={totalRecords}
						paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
						emptyMessage="Nothing to show yet. Please add some words first."
					>
						<Column field="value" header="Value" body={(word) => (
							<div onClick={() => onEdit(word.id)} style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
								{word.value}
							</div>
						)} />
						<Column field="translation" header="Translation" body={(word) => (
							<div onClick={() => onEdit(word.id)} style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
								{word.translation}
							</div>
						)} />
					</DataTable>
				</div>
				<div className="flex col-12 justify-content-between">
					<div className="flex">
					</div>
					<div className="flex">
						<Button className='flex p-button-text' type='button' icon="pi pi-angle-double-left" text disabled={isFirstPage()}
							onClick={(e) => {
								handleStart();
							}}></Button>
						<Button className='flex p-button-text' type='button' icon="pi pi-chevron-left" disabled={isFirstPage()}
							onClick={(e) => {
								handlePrev();
							}}></Button>
						<Button className='flex p-button-text' type='button' icon="pi pi-chevron-right" disabled={isLastPage()}
							onClick={(e) => {
								handleNext();
							}}></Button>
						<Button className='flex p-button-text' type='button' icon="pi pi-angle-double-right" disabled={isLastPage()}
							onClick={(e) => {
								handleEnd();
							}}></Button>

					</div>
					<div className="flex align-items-center">
						<div className="flex">
							Total: {totalRecords}
						</div>
					</div>
				</div>
			</div>

			<div className="col-12   md:col-4">
				<Button className='btn-type-5 w-full' onClick={(e) => {
					navigate('/words/learn');
				}}>Flashcards</Button>
			</div>
			<div className="col-12   md:col-4">
				<Button className='btn-type-5 w-full' onClick={(e) => {
					goPractice();
				}}>Practice with AI</Button>
			</div>
			<div className="col-12   md:col-4">
				<Button className='btn-type-5 w-full' onClick={(e) => {
					attempts();
				}}>History of Attempts</Button>
			</div >
		</div>
	);
}
